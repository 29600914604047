$theme: 'refititBlue';
.marketplace-footer {
  .sites-picture,
  .guarantee-picture {
    height: 200px;
  }

  .stimcar-experience-container {
    height: 220px;
  }

  .know-more-subtitle {
    max-width: 90%;
  }

  .links-section {
    padding: 1.5rem 5rem 2rem !important;
    background-color: #2d2a32 !important;
    .logo {
      height: 60px;
    }
    .contact-button {
      width: 75px !important;
      background: none;
    }
    .grid-mobile {
      width: 100%;
      .link {
        text-align: center;
      }
      .contact-button {
        width: 100% !important;
      }
    }
    .separator {
      height: 1px;
    }
    .social-media-icon {
      height: 2rem !important;
      width: 2rem !important;
    }
  }
}
